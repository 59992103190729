body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  color: #252525;
  font-family: Frutiger, "Opens Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('../public/assets/images/background.jpg') no-repeat center center #EEECED;
  background-size: cover;
}

@font-face {
  font-family: Frutiger;
  src: url(../public/assets/fonts/FrutigerLTPro-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: Frutiger-condensed;
  src: url(../public/assets/fonts/FrutigerLTPro-Condensed.otf) format("opentype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Frutiger;
  src: url(../public/assets/fonts/FrutigerLTPro-Roman.otf) format("opentype");
  font-weight: 400;
  font-style: normal
}

.logo{
  position: absolute;
    width: 9vw;
    top: 3.3vw;
    left: 3.3vw;
    z-index: 1000;
}

.logo img{
  max-width: 100%;
}

.slider-image{
  width: 100vw;
  height: 100vh;
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.slider-image img{
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}

.slider-intro {
  position: fixed;
  bottom: 0;
  left: 0;
  /* height: 26vh; */
  width: 100%;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: left;
  align-items: center;

  .btn{
    padding: 15px 30px !important;
    text-transform: uppercase;
    font-size: 1.3em !important;
  }
}


.slider-intro-title {
  color: #fd0;
  font-weight: 700;
  font-size: 1.9vw;
  max-width: 100%;
  /* text-align: center; */
}

.slider-intro-text {
  color: #fff;
  font-size: 1.4em;
  line-height: 1.4em;
}

.slider-intro-content {
  max-width: 60%;
  margin:  0 3.3vw;
  padding: 20px 0;
  position: relative;
  height: 100%;
  text-align: left;
}

.btn{
  padding: 13px 20px !important;
  background: #fff !important;
  font-weight: 700;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  font-size: .9em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img{
    margin-right: 5px;
    margin-left: 5px;
  }
}

.price-wrapper{
  display: flex;
  align-items: stretch;
  overflow: hidden;
  min-height: 100%;
}

.price-input-wrapper{
  width: 25%;
  overflow: hide;
  max-height: 80vh;
  padding: 20px;
}

.step-1 .price-input-wrapper{
  overflow: auto;
}


.step-3 .price-input-wrapper{
  overflow: none;
}

.step-1 .price-input-wrapper{
  background: #fff;
}

.price-input-wrapper h1{
  padding-top: 0;
  margin-top: 0;
  font-size: 1.2em;
  font-weight: 700;
}

.price-input-block{
  text-align: left;
  p{
    font-weight: bold;
    margin-top: 1.5em;
  }
   
  > div > div{
    margin: 4px 0;
    font-size: 0.85em;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;

    &.error{
      color: red;
      margin-bottom: 10px;
      text-align: right;
      padding-right: 10px;
      margin-top: 10px;
      display: block;
    }

    label{
      flex: 1;
    }

    > div{
      width: 20%;
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }
  
  > div > div.addon{
    margin-top: 1em;
  }
  
  > div > div.extra-addon{
    margin-top: .5em;
  }

  input{
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    // margin: 0 1em;
    padding: 2px;
    font-size: 1.1em;
  }
}

.main-layout{
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
}

.main-layout.step-0 > .page-wrapper{
  padding: 0;
  margin: 0;
  height: 100%;
}

.main-layout > nav{
  height: 5vh;
  margin: 20px;
  margin-top: 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  // background: red;
}

.main-layout > .page-wrapper{
  overflow: hidden;
  margin: 20px;
  height: 95vh;
}

.price-input-preview{
  max-width: 100%;
  max-height: 100%;
  display: flex;
  width: 75%;
  justify-content: right;
  background: transparent;
  position: relative;

  &.margin-top{
    padding-top: 40px;
  }

  > a {
    position: absolute;
    top: 0;
    background: white;
    color: black;
    text-decoration: none;
    padding: 5px 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 20px;
      margin-right: 5px;
    }
  }
}

.price-sheet-pdf-wrapper{
  height: auto;
  max-height: 100%;
  aspect-ratio: 1.415;
  background: url('../public/assets/images/tapis-bg.png') no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: relative;

  > .price{
    position: absolute;
    // color: red;
    font-size: 1.5em;
    font-weight: bold;

    &.addon{
      font-size: 1.2em;
    }
    &.extra-addon{
      font-size: 0.7em;
    }
  }
}

.form-group{
  p{
    font-size: 0.9em;
    font-weight: bold;
  }

  input{
    border: none;
    background: white;
    padding: 10px;
    font-size: .9em;
  }
}

.step-2 .page-wrapper{
  background: none;
}

.shop-details{
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0px;
  align-items: center;

  .shop-name{
    text-transform: uppercase;
    font-size: 1em;
    padding: 5px 20px;
    font-weight: bold;
  }

  .shop-logo {
    font-size: 0;
    padding: 5px 20px;

    img{
      max-height: 5vh;
    }
  }
}